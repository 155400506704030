import { render, staticRenderFns } from "./BalancedSlider.vue?vue&type=template&id=064ffd26&scoped=true"
import script from "./BalancedSlider.vue?vue&type=script&lang=ts"
export * from "./BalancedSlider.vue?vue&type=script&lang=ts"
import style0 from "./BalancedSlider.vue?vue&type=style&index=0&id=064ffd26&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064ffd26",
  null
  
)

export default component.exports